import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, PageSection } from '~styles/global'
import RichText from '~components/RichText'

const FullWidthText = ({ className, content, highlight }) => {
	return (
		<Wrap className={className}>
			<Text 
				css={css`
					a {
						color: ${highlight};
						&:after{
							background-color: ${highlight};
						}
					}
			`}
			>
				<RichText content={content?.text}/>
			</Text>
		</Wrap>
	)
}

const Wrap = styled(PageSection)`
	margin: var(--large) 0;
`
const Text = styled.div`
	grid-column: span 4;
	max-width: 1120px;
	h2 {
		a {
			text-decoration: none;
			position: relative;
			white-space: nowrap;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				height: 2px;
				background-color: var(--feature);
				top: calc(100% - 6px);
				${tablet}{
					top: calc(100% - 5px);
				}
			}
		}
		&:not(:first-of-type){
			text-indent: 71px;
			${tablet}{
				text-indent: 44px;
			}
		}
	}
	a {
		text-decoration: underline;
	}
	${mobile}{
		grid-column: span 1;
	}
`

FullWidthText.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	highlight: PropTypes.string,
}

export default FullWidthText