import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import Button from '~components/Button'
import { Triangle } from '~components/Svg'
import SanityLink from '~components/SanityLink'

const SidebarDropdown = ({ className, menuItems, currentPage, buttonLink, buttonText, color }) => {

	const [open, setOpen] = useState()

	return (
		<Wrap className={className}>
			<Toggle
				onClick={()=> setOpen(!open)}
				css={css`color: ${color};`}
			>
				<RightTriangle 
					css={css`
						transform: ${open ? 'rotate(90deg)' : 'rotate(0deg)'};
					`}
				/>
				{currentPage}

			</Toggle>
			<Menu 
				css={css`
					opacity: ${open ? '1' : '0'};
					pointer-events: ${open ? 'all' : 'none'};
			`}>
				{menuItems.map(item => (
					<MenuItem 
						to={resolveLink(item)} 
						key={item.title}
						css={css`
						display: ${currentPage === item.title ? 'none' : 'grid'};
						color: ${color};
						`}
					>
						<Bullet>○</Bullet>{item.title}
					</MenuItem>
				))}
				{buttonText &&
					<Booking link={buttonLink}><Arrow>→</Arrow>{buttonText}</Booking>
				}
			</Menu>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
`
const Toggle = styled.button`
	border-top: 1px solid var(--black);
	border-bottom: 1px solid var(--black);
	padding: var(--xxs) 0;
	width: 100%;
  text-align: left;
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	font-weight: 500;
`
const RightTriangle = styled(Triangle)`
	width: 13px;
	margin-right: var(--xs);
`
const Menu = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 1;
	background-color: var(--white);
`
const MenuItem = styled(Link)`
	display: grid;
	grid-template-columns: max-content 1fr;
	margin-bottom: 2px;
	font-weight: 500;
	width: 100%;
	border-bottom: 1px solid var(--black);
	padding: var(--xxs) 0;
	:last-of-type{
		border-bottom: none;
	}
`
const Bullet = styled.div`
	margin-right: var(--xs);
`
const Booking = styled(SanityLink)`
	padding: var(--xxs) 0;
	font-weight: 700;
	display: block;
	border-bottom: 1px solid;
`
const Arrow = styled.span`
	font-weight: 400;
	display: inline-block;
	margin-right: 13px;
`
SidebarDropdown.propTypes = {
	className: PropTypes.string,
	menuItems: PropTypes.array,	
	currentPage: PropTypes.string,
	buttonLink: PropTypes.string,
	buttonText: PropTypes.string,
	color: PropTypes.string,
}

export default SidebarDropdown