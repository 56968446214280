import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, PageSection } from '~styles/global'
import RichText from '~components/RichText'
import Image from '~components/Image'

const ImageGrid = ({ className, content }) => {
	return (
		<Wrap className={className}>
			{content.tiles?.map((tile, i) => (
				<Tile key={`tile${i}`}>
					<TileImage image={tile.image} aspectRatio={1.05}/>
					<Text>
						<RichText content={tile.text}/>
					</Text>
				</Tile>
			))}
		</Wrap>
	)
}

const Wrap = styled(PageSection)`
	margin: var(--large) 0;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: var(--xxxl);
	${mobile}{
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 16px;
		grid-row-gap: var(--m);
	}
`
const Tile = styled.div`
`
const TileImage = styled(Image)`
	margin-bottom: var(--s);
	background-color: var(--cream);
`
const Text = styled.div`
	${mobile}{
		font-size: 10px;
	}
`
ImageGrid.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default ImageGrid