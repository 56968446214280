import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, PageSection } from '~styles/global'
import { LargeArrow, AirBnb } from '~components/Svg'

const BookingButtons = ({ className, content }) => {
	return (
		<Wrap className={className}>
			{content.airbnbLink &&
				<LargeButton href={content.airbnbLink} 
					target='_blank' 
					rel='noreferrer'
				>
					<ArrowLarge />
					<ImageCol>
						<AirBnb 
							css={css`
							width: 100%;
							max-width: 210px;
					`}
						/>
					</ImageCol>
				</LargeButton>
			}
			{content.riparideLink &&
				<LargeButton 
					href={content.riparideLink} 
					target='_blank' 
					rel='noreferrer'>
					<ArrowLarge />
					<ImageCol>
						<Img 
							src="/images/Riparide.png" 
							alt="Riparide"
							css={css`
						max-width: 290px;
					`}
				 />
					</ImageCol>
				</LargeButton>
			}
		</Wrap>
	)
}

const Wrap = styled(PageSection)`
	padding: 0 52px;
	margin: 126px 0;
	${tablet}{
		padding: 0 26px;
		margin: 56px 0;
	}
`
const LargeButton = styled.a`
	background-color: var(--feature);
	grid-column: span 2;
	border-radius: 10px;
	color: var(--white);
	display: grid;
	grid-template-columns: max-content 1fr;
	padding: var(--m) var(--xxl);
	align-items: center;
	${tablet}{
		padding: var(--m) var(--xl);
	}
	${mobile}{
		grid-column: span 1;
		margin-bottom: var(--large);
		min-height: 112px;
	}
`
const ImageCol = styled.div`
	justify-self: center;
`
const Img = styled.img`
	width: 100%;
`
const ArrowLarge = styled(LargeArrow)`
	width: 48px;
	margin-right: var(--m);
	${mobile}{
		width: 36px;
	}
`
BookingButtons.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default BookingButtons