import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useState } from 'react'

const YoutubePlayer = ({ className, content }) => {

	let video = content?.youtubeUrl

	console.log(module)

	if (video?.includes('youtube') && video?.includes('?v=')) {
		video = video.split('?v=')[1]
		video = `https://www.youtube.com/embed/${video}`
	} else if (video?.includes('youtube')) {
		video = video.split('embed/')[1]
		video = `https://www.youtube.com/embed/${video}`
	} 

	  // youtube play function 
	const [play, setPlay] = useState(false)
	const url = play ? `${video}?autoplay=1` : video

	return (
		<Wrap className={className}>
			<iframe 
				src={url} 
				frameBorder='0' 
				allowFullScreen
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				css={css`
					position: absolute; 
					top: 0; 
					left: 0; 
					width: 100%; 
					height: 100%; 
			`}>
			</iframe>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative; 
	padding-bottom: 56.25%;
	height: 0; 
	overflow: hidden; 
	max-width: 100%;
	margin: var(--large) 0;
`

YoutubePlayer.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default YoutubePlayer