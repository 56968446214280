import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, PageSection } from '~styles/global'
import RichText from '~components/RichText'

const TwoColumnText = ({ className, content, highlight }) => {

	const Wrap = styled(PageSection)`
		margin: var(--large) 0;
		${mobile}{
			grid-row-gap: var(--large);
		}
	`

	const Column = styled.div`
		grid-column: span 2;
		> div {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: ${content.verticalAlign === 'bottom' ? 'flex-end' : 'flex-start'};
		}
		> div > * {
		}
		${mobile}{
			grid-column: span 1;
			> div {
				display: block;
			}
		}
	`

	return (
		<Wrap className={className} css={css`a{ color: ${highlight}; border-color: ${highlight}}`}>
			<Column>
				<RichText content={content.leftColumn} />
			</Column>
			<Column>
				<RichText content={content.rightColumn}/>
			</Column>
		</Wrap>
	)
}

TwoColumnText.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	highlight: PropTypes.string,
}

export default TwoColumnText