import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import RichText from '~components/RichText'

const FullWidthImage = ({ className, content }) => {
	return (
		<Wrap className={className}>
			{(content.image?.caption && content.captionPosition === 'above') && 
			<Above>
				<CaptionAbove className='small'><RichText content={content.image.caption} /></CaptionAbove>
			</Above>
			}
			<MainImage image={content.image}/>
			{(content.image?.caption && content.captionPosition === 'below') && 
				<Caption className='small'><RichText content={content.image.caption} /></Caption>
			}
			<CaptionMobile className='small'>
				<RichText content={content.image.caption} />
			</CaptionMobile>
		</Wrap>
	)
}

const Wrap = styled.div`
	margin: var(--large) 0;
	position: relative;
`
const Above = styled.div`
	position: absolute;
	top: calc((var(--large) * -1));
	width: 100%;
	${mobile}{
		display: none;
	}
`
const CaptionAbove = styled.div`
	position:absolute;
	bottom: 0;
	width: 25%;
	max-width: 200px;
	color: var(--feature);
`
const MainImage = styled(Image)`
	margin-bottom: var(--xxs);
`
const Caption = styled.div`
	color: var(--feature);
	${mobile}{
		display: none;
	}
`
const CaptionMobile = styled(Caption)`
	display: none;
	${mobile}{
		display: block;
	}
`
FullWidthImage.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default FullWidthImage