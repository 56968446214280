import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import ResidentGrid from '~components/ResidentGrid'

const ResidentAccordion = ({ className, content }) => {
	return (
		<Wrap className={className}>
			{content.residentsGridArray?.map((residentGrid, i) => (
				<ResidentGrid key={residentGrid._key} content={residentGrid} index={i}/>
			))}
		</Wrap>
	)
}

const Wrap = styled.div`
	
`

ResidentAccordion.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default ResidentAccordion