import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, PageSection } from '~styles/global'
import Image from '~components/Image'
import RichText from '~components/RichText'

const DoubleImage = ({ className, content, highlight }) => {
	return (
		<Wrap className={className} css={css`a{ color: ${highlight}; border-color: ${highlight}}`}>
			<Column>
				<Image 
					image={content.leftImage} 
					css={css`
						margin-bottom: ${content.leftImage.caption ? 'var(--s)' : '0px'};
				`}/>
				<Caption css={css`color: ${highlight};`} className='small' >
					<RichText content={content.leftImage.caption }/>
				</Caption>
			</Column>
			<Column>
				<Image 
					image={content.rightImage} 
					css={css`
						margin-bottom: ${content.rightImage.caption ? 'var(--s)' : '0px'};
				`}/>
				<Caption css={css`color: ${highlight};`} className='small' >
					<RichText content={content.rightImage.caption }/>
				</Caption>
			</Column>
		</Wrap>
	)
}

const Wrap = styled(PageSection)`
	margin: var(--large) 0;
	${mobile}{
		grid-row-gap: var(--large);
	}
`
const Column = styled.div`
	grid-column: span 2;
	${mobile}{
		grid-column: span 1;
	}
`
const Caption = styled.div`
	grid-column: 2/3;
	${mobile}{
		grid-column: span 1;
	}
`
DoubleImage.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	highlight: PropTypes.string,
}

export default DoubleImage