import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import RichText from '~components/RichText'
import Button from '~components/Button'
import PageSection from '~styles/global'
import Image from '~components/Image'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'


const ResidentTile = ({className, resident}) => {
	const [textOpen, setTextOpen] = useState(false)
	const [aqOpen, setAqOpen] = useState(false)

	return(
		<Resident >
			<ResImage image={resident.image} aspectRatio={1.603}/>
			<ResTitle>{resident.title}</ResTitle>
			<ResSubtitle>{resident.subtitle}</ResSubtitle>
			<VisibleText css={css`
				/* margin-bottom: ${textOpen ? '0' : 'var(--m)'}; */
			`}>
				<RichText content={resident.visibleText}/>
			</VisibleText>
			<SlideDown
				className={'my-dropdown-slidedown'} 
				css={css`
						transition: 0.4s ease-in-out;
				`}>
				{textOpen && 
					<HiddenText>
						<RichText content={resident.hiddenText}/>
					</HiddenText>
				}
			</SlideDown>
			<ReadToggle
				onClick={()=> setTextOpen(!textOpen)}
				css={css`
					&:last-of-type{
						border-bottom: ${aqOpen ? '1px solid' : 'none'};
					}
				`}
			>
				{textOpen ? 'Read Less' : 'Read More'}
			</ReadToggle>
			<SlideDown>
				{aqOpen && 
						<AquitalTextContainer>
							<AquitalText>
								<RichText content={resident.acquittalText}/>
							</AquitalText>
						</AquitalTextContainer>
				}
			</SlideDown>
			<Aquital css={css`
				background-color: ${aqOpen ? 'var(--cream)' : 'var(--white)'};
			`}>
				<AquittalToggle
					onClick={()=> setAqOpen(!aqOpen)}
					css={css`
					color: var(--black);
					margin: ${aqOpen ? '0 var(--m) var(--xs) var(--m)' : '0'};
					&:last-of-type{
						border-bottom: ${aqOpen ? 'none' : '1px solid'};
					}
				`}
				>
					{aqOpen ? 'Close' : 'View Acquittal Document'}
				</AquittalToggle>
			</Aquital>
		</Resident>
	)

}

const ResidentGrid = ({ className, content, index }) => {
	const [open, setOpen] = useState()

	useEffect(() => {
		if(index === 0) {
			setOpen(true)
		}
	}, [index])
	
	return (
		<Wrap className={className}>
			<Title 
				className='h1'
				onClick={()=> setOpen(!open)}
			>
				<Dash>{open ? '—' : '+'}</Dash>{content?.title}
			</Title>
			<SlideDown>
				{open && 
					<Grid>
						{content?.residents.map(resident=> (
							<ResidentTile key={resident.title} resident={resident}/>
						))}
					</Grid>
				}
			</SlideDown>
		</Wrap>
	)
}

const Wrap = styled.div`
	
`
const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 26px;
	margin-top: var(--xl);
  ${mobile}{
		grid-template-columns: repeat(1, 1fr);
  }
`
const Title = styled.button`
	border-top: 1px solid var(--feature);
	border-bottom: 1px solid var(--feature);
	padding: var(--xxxxs) 0;
	color: var(--feature);
	text-align: left;
	width: 100%;
	margin-top: -1px;
`
const Dash = styled.span`
	margin-right: var(--l);
	display: inline-block;
	min-width: 1em;
`
const Resident = styled.div`
	margin-bottom: var(--large);
`
const ResImage = styled(Image)`
	margin-bottom: var(--m);
`
const ResTitle = styled.div`
	font-weight: 700;
`
const ResSubtitle = styled.div`
	margin-bottom: var(--l);
`
const VisibleText = styled.div`
`
const ReadToggle = styled(Button)`
	margin-top: var(--m);
	color: var(--feature);
	${mobile}{
		margin-top: var(--xl);
	}
`
const HiddenText = styled.div`
	
`
const Aquital = styled.div`
	display: flex;
  flex-direction: column;
`
const AquittalToggle = styled(Button)`
	width: auto;
`
const AquitalTextContainer = styled.div`
	background-color: var(--cream);
	overflow: auto;
	margin-top: var(--xxs);
	${mobile}{
		margin-top: var(--s);
	}
`
const AquitalText = styled.div`
	margin: var(--s) var(--m);
`
ResidentGrid.propTypes = {
	className: PropTypes.string,
	content: PropTypes.string,
	index: PropTypes.number,
}
ResidentTile.propTypes ={
	className: PropTypes.string,
	resident: PropTypes.string,
}


export default ResidentGrid