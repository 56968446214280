import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import FullWidthText from '~components/FullWidthText'
import FullWidthImage from '~components/FullWidthImage'
import ImageSlider from '~components/ImageSlider'
import TwoColumnText from '~components/TwoColumnText'
import DoubleImage from '~components/DoubleImage'
import Space from '~components/Space'
import ImageGrid from '~components/ImageGrid'
import ResidentGrid from '~components/ResidentGrid'
import ActivityGrid from '~components/ActivityGrid'
import BookingButtons from '~components/BookingButtons'
import ResidentAccordion from '~components/ResidentAccordion'
import YoutubePlayer from '~components/YoutubePlayer'

const RenderModules = ({ className, modules, highlight }) => {
	if(!modules) return null
	console.log(modules)
	return (
		<div className={className}>
			{modules.map(module => <RenderModule key={module._key} module={module} highlight={highlight}/>)}
		</div>
	)
}

const RenderModule = ({ module, highlight }) => {
	switch (module._type) {
	case 'fullWidthText':
		return <FullWidthText content={module} highlight={highlight}/>	
	case 'twoColumnText':
		return <TwoColumnText content={module} highlight={highlight}/>
	case 'fullWidthImage':
		return <FullWidthImage content={module} highlight={highlight}/>
	case 'doubleImage':
		return <DoubleImage content={module} highlight={highlight}/>
	case 'imageSlider':
		return <ImageSlider content={module} highlight={highlight}/>
	case 'imageGrid':
		return <ImageGrid content={module} />
	case 'residentGrid':
		return <ResidentGrid content={module}/>
	case 'residentAccordion':
		return <ResidentAccordion content={module}/>
	case 'activityGrid':
		return <ActivityGrid content={module}/>
	case 'bookingButtons':
		return <BookingButtons content={module}/>
	case 'youtubeVideo':
		return <YoutubePlayer content={module}/>
	case 'space':
		return <Space size={module.size}/>
	default:
		return null
	}
}

RenderModules.propTypes = {
	className: PropTypes.string,
	modules: PropTypes.object,
	highlight: PropTypes.string,
}

RenderModule.propTypes = {
	module: PropTypes.object,
	highlight: PropTypes.string,
}

export default RenderModules