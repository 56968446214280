import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, PageSection } from '~styles/global'
import Button from '~components/Button'
import RichText from '~components/RichText'
import Image from '~components/Image'
import { AnimatePresence, motion } from 'framer-motion'

const ActivityGrid = ({ className, content }) => {
	const [view, setView] = useState()

	useEffect(() => {
		setView('all')
	}, [])

	const currentActivities = content.activities.filter(activity => {
		return activity.category?.title === view
	})

	return (
		<Wrap className={className}>
			<Menu>
				<Category 
					onClick={() => setView('all')}
					css={css`
							color: ${view === 'all' ? 'var(--feature)' : 'inherit'};
							z-index: ${view === 'all' ? '1' : '0'};
					`}
				>
					<Bullet>{view === 'all' ? '●' : '○'}</Bullet>All
				</Category>
				{content.categories?.map(category => (
					<Category 
						key={category.key} 
						onClick={() => setView(category.title)}
						css={css`
							color: ${view === category.title ? 'var(--feature)' : 'inherit'};
							z-index: ${view === category.title ? '1' : '0'};
					`}
					>
						<Bullet>{view === category.title ? '●' : '○'}</Bullet>{category.title}
					</Category>
				))}
			</Menu>
			<AnimatePresence exitBeforeEnter>
				<motion.div 
					key={view}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.3}}
				>
					<Grid>
						{view === 'all' ? 
							content.activities.map(activity => (
								<Activity key={activity.title}>
									<AImage image={activity.image} aspectRatio={1.603}/>
									<ATitle>{activity.title}</ATitle>
									<ASubtitle>{activity.subtitle}</ASubtitle>
									<AText>
										<RichText content={activity.text}/>
									</AText>
									{activity.buttons?.map(button => (
										<AButton link={button.link} key={button.text}>
											{button.text}
										</AButton>
									))}
								</Activity>
							))
							: 
							currentActivities.map(activity => (
								<Activity key={activity.title}>
									<AImage image={activity.image}/>
									<ATitle>{activity.title}</ATitle>
									<ASubtitle>{activity.subtitle}</ASubtitle>
									<AText>
										<RichText content={activity.text}/>
									</AText>
									{activity.buttons?.map(button => (
										<AButton link={button.link} key={button.text}>
											{button.text}
										</AButton>
									))}
								</Activity>
							))
						}
					</Grid>
				</motion.div> 
			</AnimatePresence>	
		</Wrap>
	)
}

const Wrap = styled.div`	
`
const Menu = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 26px;
	margin-bottom: var(--xxl);
	${mobile}{
		grid-template-columns: 1fr;
		margin-bottom: var(--l)
	}
`
const Category = styled.button`
	border-top: 1px solid;
	border-bottom: 1px solid;
	font-weight: 500;
	padding: var(--xxxs) 0;
	text-align: left;
	position: relative;
	${mobile}{
		margin-top: -1px;
		:first-of-type {
			border-top: 1px solid;
		}
		:last-of-type {
			border-bottom: none;
		}
	}
`
const Bullet = styled.div`
	display: none;
	margin-right: var(--xxs);
	${mobile}{
		display: inline-block;
	}
`
const Grid = styled(PageSection)`
`
const Activity = styled.div`
	grid-column: span 2;
	margin-bottom: var(--large);
	${mobile}{
		grid-column: span 1;
	}
`
const AImage = styled(Image)`
	margin-bottom: var(--xs);
	${mobile}{
		margin-bottom: var(--s);
	}
`
const ATitle = styled.div`
	font-weight: 600;
`
const ASubtitle = styled.div`
	margin-bottom: var(--s);
	${mobile}{
		margin-bottom: var(--l)
	}
`
const AText = styled.div`
	margin-bottom: var(--xs);
`
const AButton = styled(Button)`
	border-top: none;
	color: var(--feature);
	&:last-of-type {
		border-bottom: none;
	}
	${mobile}{
		font-size: 15px;
		line-height: 1.23;
		letter-spacing: 0.0em;
	}
`

ActivityGrid.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default ActivityGrid